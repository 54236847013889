
import Vue, { PropType } from "vue";
import { SharedManuscriptStorage } from "@/lib/storages";
import { Manuscript, SharedLink, OverallPlot, SubPlot, SharedLinkKind } from "@/lib/models";
import shareThis from "share-this";
import ClickOutside from "vue-click-outside";
import Plots from "@/components/organisms/Plots.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Plots },
  directives: {
    ClickOutside,
  },
  props: {
    sharedLinkId: {
      type: String as PropType<string>,
    },
    novelId: {
      type: String as PropType<string>,
    },
    contentKey: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: "",
      SharedLinkKind,
    };
  },
  computed: {
    sharedLink() {
      return this.$store.getters["sharedLinkModule/sharedLink"];
    },
    manuscriptList() {
      return this.$store.getters["sharedLinkModule/manuscriptList"];
    },
    title() {
      const { contentKey } = this;
      if (!contentKey) return "";

      const manuscriptList = this.manuscriptList as Manuscript[];
      const manuscript = manuscriptList.find((manuscript) => manuscript.key === contentKey);
      return manuscript ? manuscript.title! : "（タイトル未設定）";
    },
    quoteSharer() {
      const dispatch = (text: string) => {
        this.$store.dispatch("sharedLinkModule/onClickQuote", text);
      };
      return {
        name: "quotes",
        render(text, rawText, refUrl) {
          this.rawText = rawText;
          return (
            '<a title="引用" href="#">' +
            '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">' +
            '<path d="M0 0h24v24H0z" fill="none"/><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/>' +
            "</svg>" +
            "</a>"
          );
        },
        active: true,
        action(event) {
          dispatch(this.rawText);
        },
      };
    },
    plot() {
      const plotList = this.$store.getters["sharedLinkModule/plotList"];

      return plotList.find((plot: OverallPlot | SubPlot) => plot.plotId === this.contentKey);
    },
  },
  methods: {
    async getContent() {
      if (this.kind !== SharedLinkKind.MANUSCRIPT) return;
      const { novelId, contentKey } = this;
      const { userId } = this.sharedLink as SharedLink;
      const sharedManuscriptStorage = new SharedManuscriptStorage();
      this.content = await sharedManuscriptStorage.downloadFile(novelId, contentKey, userId!);
    },
    initializeQuote() {
      this.$store.dispatch("sharedLinkModule/initializeQuote");
    },
  },
  created() {
    shareThis({
      selector: ".textarea",
      sharers: [this.quoteSharer],
    }).init();
  },
  watch: {
    contentKey: {
      handler() {
        if (this.contentKey) {
          this.content = this.getContent();
        } else {
          this.content = "";
        }
      },
      immediate: true,
    },
  },
});

interface Props {
  sharedLinkId: string;
  novelId: string;
  contentKey: string;
  kind: string;
}

interface Data {
  content: string;
  SharedLinkKind: typeof SharedLinkKind;
}

interface Computed {
  sharedLink: SharedLink;
  manuscriptList: Manuscript[];
  title: string;
  quoteSharer: object;
  plot: OverallPlot | SubPlot;
}

interface Methods {
  getContent: () => void;
  initializeQuote: () => void;
}
