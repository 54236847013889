
import Vue, { PropType } from "vue";
import { Novel, Manuscript, PlotList, SharedLinkKind } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      SharedLinkKind,
    };
  },
  props: {
    sharedLinkId: {
      type: String as PropType<string>,
    },
    novelId: {
      type: String as PropType<string>,
    },
    contentKey: {
      type: String,
      default: "",
    },
  },
  methods: {
    async select(contentKey, kind) {
      const { sharedLinkId, novelId } = this;
      this.$router.push({
        name: "sharedContent",
        params: {
          sharedLinkId,
          novelId,
          contentKey,
        },
      });

      this.$emit("contentSelect", kind);
    },
  },
  computed: {
    novel() {
      return this.$store.getters["sharedLinkModule/novel"];
    },
    manuscriptList() {
      return this.$store.getters["sharedLinkModule/manuscriptList"];
    },
    plotList() {
      return this.$store.getters["sharedLinkModule/plotList"];
    },
    novelTitle() {
      const { title } = this.novel;
      return title || "(作品タイトル未設定)";
    },
  },
});

interface Data {
  SharedLinkKind: typeof SharedLinkKind;
}

interface Props {
  sharedLinkId: string;
  novelId: string;
  contentKey: string;
}

interface Computed {
  novel: Novel;
  manuscriptList: Manuscript[];
  plotList: PlotList;
  novelTitle: string;
}

interface Methods {
  select: (contentKey: string, kind: string) => void;
}
