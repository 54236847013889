
import Vue, { PropType } from "vue";
import { DeleteSubPlotPayload } from "@/lib/models/plot";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    novelId: String as PropType<string>,
    plotId: String as PropType<string>,
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "削除する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { $router, novelId, plotId } = this;

      const deleteSubPlotPayload: DeleteSubPlotPayload = {
        novelId,
        plotId,
        callback: async () => {
          // 子プロットの紐付き削除で更新される場合があるので再取得する
          await this.$store.dispatch("plotModule/initialize", novelId);
          $router.push({ name: "plot", params: { novelId } });
        },
      };
      this.$store.dispatch("plotModule/deleteSubPlot", deleteSubPlotPayload);

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
});

interface Props {
  novelId: string;
  plotId: string;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {}

interface Computed {}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
}

export type PlotDeleteDialogProps = Props;
