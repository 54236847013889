
import Vue, { PropType } from "vue";
import { ToggleButton } from "vue-js-toggle-button";
import ExportVariantIcon from "icons/ExportVariant.vue";
import ContentSaveOutlineIcon from "icons/ContentSaveOutline.vue";
import CogIcon from "icons/Cog.vue";
import NoteTextOutlineIcon from "icons/NoteTextOutline.vue";
import PaletteIcon from "icons/Palette.vue";
import DeleteIcon from "icons/Delete.vue";
import SwapHorizontalIcon from "icons/SwapHorizontal.vue";
import TrayArrowDownIcon from "icons/TrayArrowDown.vue";
import ToolboxOutlineIcon from "icons/ToolboxOutline.vue";
import FormatIndentIncreaseIcon from "icons/FormatIndentIncrease.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import HistoryIcon from "icons/History.vue";
import LinkIcon from "icons/LinkVariant.vue";

const ClickOutside = require("vue-click-outside");

export interface Item {
  name: string;
  value?: any;
  icon?: string;
  color?: string;
  fontStyle?: object;
  callback(): void;
}

export const Position = {
  /** 上 */
  top: "top",
  /** 下 */
  bottom: "bottom",
} as const;
// eslint-disable-next-line no-redeclare
export type Position = typeof Position[keyof typeof Position];

interface Props {
  items: Item[];
  togglableItems: Item[];
  styleObject: object;
  position: Position;
}

interface Data {
  isOpen: boolean;
}

interface Computed {
  fillColor(color?: string): string;
}

interface Methods {
  onClickMenu(): void;
  onClickItem(item: any): void;
  onClickOutside(): void;
  onChangeState(callback: Function): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: {
    ClickOutside,
  },
  components: {
    ToggleButton,
    ExportVariantIcon,
    ContentSaveOutlineIcon,
    CogIcon,
    NoteTextOutlineIcon,
    PaletteIcon,
    SwapHorizontalIcon,
    DeleteIcon,
    TrayArrowDownIcon,
    ToolboxOutlineIcon,
    FormatIndentIncreaseIcon,
    CheckboxMarkedOutlineIcon,
    HistoryIcon,
    LinkIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    togglableItems: {
      type: Array,
      default: () => [],
    },
    styleObject: Object,
    position: {
      type: String as PropType<Position>,
      default: Position.top,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    fillColor() {
      return (color) => {
        if (color) return color;
        return "#A0A0A0";
      };
    },
  },
  methods: {
    onClickOutside() {
      this.isOpen = false;
      this.$store.commit("plotModule/setIsHamburgerMenuOpen", false);
    },
    onClickMenu() {
      this.isOpen = !this.isOpen;
      this.$store.commit("plotModule/setIsHamburgerMenuOpen", this.isOpen);
    },
    onClickItem(item) {
      item.callback();
      this.onClickMenu();
    },
    onChangeState(callback) {
      callback();
    },
  },
});
