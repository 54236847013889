
import Vue from "vue";

const defaultIcon = require("@/assets/img/icon/edit.png");

export default Vue.extend({
  props: {
    to: {
      type: Object,
      required: false,
    },
    icon: {
      type: String,
      default: defaultIcon,
    },
    size: {
      type: Number,
      default: 35,
    },
  },
  methods: {
    click() {
      if (this.$props.to) {
        this.$router.push(this.$props.to);
      }
      this.$emit("click");
    },
  },
});
