
import Vue, { PropType } from "vue";
import SharedLinkList from "@/components/organisms/SharedLinkList.vue";
import SharedLinkContent from "@/components/organisms/SharedLinkContent.vue";
import SharedLinkComment from "@/components/organisms/SharedLinkComment.vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import { SharedLink } from "@/lib/models";
import isMobile from "ismobilejs";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    SharedLinkList,
    SharedLinkContent,
    SharedLinkComment,
    CheckboxIcon,
  },
  props: {
    sharedLinkId: {
      type: String as PropType<string>,
    },
    novelId: {
      type: String as PropType<string>,
    },
    contentKey: {
      type: String as PropType<string>,
    },
  },
  data() {
    return {
      isChecked: false,
      isAgreed: false,
      kind: "",
    };
  },
  methods: {
    onClickCheckbox() {
      this.isChecked = !this.isChecked;
    },
    onClickStart() {
      this.isAgreed = true;
    },
    onContentSelect(kind) {
      this.kind = kind;
    },
  },
  computed: {
    sharedLink() {
      return this.$store.getters["sharedLinkModule/sharedLink"] as SharedLink;
    },
    isEnabledSharedLink() {
      const sharedLink = this.sharedLink as SharedLink;
      return sharedLink ? sharedLink.enabled : false;
    },
  },
  created() {
    const { sharedLinkId, novelId } = this;
    this.$store.dispatch("sharedLinkModule/initializeSharedLink", {
      sharedLinkId,
      novelId,
    });
    this.$store.dispatch("sharedLinkModule/initializeCommentList", sharedLinkId);
    this.$store.dispatch("userModule/initialize");

    // 共有リンク画面でリロードされた場合、共有リンク画面を初期化する
    if (this.$route.params.contentKey) {
      this.$router.replace({
        name: "sharedContent",
        params: {
          sharedLinkId,
          novelId,
        },
      });
    }
  },
  watch: {
    sharedLink() {
      if (!this.isEnabledSharedLink || isMobile().phone || (isMobile().tablet && window.innerWidth < 2048)) {
        this.$router.push({
          name: "sharedLinkAccessDenied",
          query: { reason: "disabled" },
        });
      }
    },
  },
});

interface Props {
  sharedLinkId: string;
  novelId: string;
  contentKey: string;
}

interface Data {
  isChecked: boolean;
  isAgreed: boolean;
  kind: string;
}

interface Methods {
  onClickCheckbox: () => void;
  onClickStart: () => void;
  onContentSelect: (kind: string) => void;
}

interface Computed {
  sharedLink: SharedLink;
  isEnabledSharedLink: boolean | undefined;
}
